import React from 'react';
import TestimonialSliderButton from './TestimonialSliderButton';

interface TestimonialFormProps {    
  bgImage: string;  
  handlePrevClick: () => void;
  handleNextClick: () => void;
  blockquote: string;
  name: string;
  title: string;
  description: string;
}

const TestimonialForm: React.FC<TestimonialFormProps> = ({ bgImage,
     handlePrevClick,
      handleNextClick, 
      blockquote, 
      name, 
      title, 
      description 
    }) => {


  return (
    <section className="relative flex-none w-1/2 min-h-screen overflow-hidden">
      <img
        src={bgImage}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover rounded-tl-[80px] rounded-bl-[80px]"
      />
      <div className="relative flex flex-col justify-end h-full p-14 z-10">
        <blockquote className="text-4xl font-medium tracking-tighter leading-10 text-white mb-6">
          {blockquote}
        </blockquote>
        <div>
          <h2 className="text-3xl font-semibold leading-10 text-white mb-3">
            {name}
          </h2>
          <div className="flex justify-between items-end">
            <div className="text-white">
              <p className="text-lg font-semibold leading-7">
                {title}
              </p>
              <p className="text-base font-medium leading-6">
                {description}
              </p>
            </div>
            <div className="flex gap-4">
              <TestimonialSliderButton
                variant="prev"
                onClick={handlePrevClick}
              />
              <TestimonialSliderButton
                variant="next"
                onClick={handleNextClick}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialForm;
