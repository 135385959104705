import FormLabel from 'components/Forms/FormLabel';
import FormContainer from 'components/Forms/FormContainer';
import FormContent from 'components/Forms/FormContent';
import SectionWrapper from 'components/Common/SectionWrapper';
import Toggle from "components/Forms/Toggle";
import { useNavigate } from 'react-router-dom';

const TemplateSection = () => {
    const navigate = useNavigate();
    const onSelect = (tool: string) => {
        navigate(`/prompt/${tool}`);
    }
    return (
        <SectionWrapper title="Templates" description="Manage your templates for the different tools.">
            <FormContainer>
                <FormLabel label="Tools" description="Choose the tool you want to manage templates for." />
                <FormContent>
                <Toggle
                    options={[
                        { caption: "Cold Outreach", value: "LMG" },
                        { caption: "Copywriting", value: "COPYWRITING" },
                    ]}
                    onSelect={onSelect}
                />
                </FormContent>
            </FormContainer>
        </SectionWrapper>
    )
}

export default TemplateSection;