import React from 'react';
import logoImage from 'assets/images/DecaidLabs-anurati.png';

const Logo: React.FC = () => {
  return (
    <div className="flex absolute top-8 left-8 z-0 items-start max-w-full w-[200px]">
      <img
        loading="lazy"
        src={logoImage}
        className=" w-[284px]"
        alt="Company Logo"
      />
    </div>
  );
};

export default Logo;
