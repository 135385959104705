import React, { useState } from 'react';
import maxImage from 'assets/images/max.jpeg';
import felixImage from 'assets/images/felix.jpeg';
import TestimonialForm from './TestimonialForm';

const Testimonial: React.FC = () => {
  const images = [
    maxImage,
    felixImage,
  ];
  const testimonials = [
    {
      blockquote: "We are developing DECAID Forge to create the ultimate powerhouse hosting all the AI tools you need.",
      name: "Maximilian Möhring",
      title: "CEO, DECAID Group",
      description: "Europe's Powerhouse for GenAI Adoption"
    },
    {
      blockquote: "DECAID Labs stands for research and innovation in the field of AI. Forge mirrors exactly those values.",
      name: "Felix Leber",
      title: "CTO, DECAID Labs",
      description: "Europe's Powerhouse for GenAI Adoption"
    }
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);


  const handlePrevClick = () => {
    setCurrentTestimonial((prev) => 
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  const handleNextClick = () => {
    setCurrentTestimonial((prev) => 
      prev === testimonials.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <TestimonialForm bgImage={images[currentTestimonial]} 
    handlePrevClick={handlePrevClick} 
    handleNextClick={handleNextClick} 
    blockquote={testimonials[currentTestimonial].blockquote} 
    name={testimonials[currentTestimonial].name} 
    title={testimonials[currentTestimonial].title} 
    description={testimonials[currentTestimonial].description} />
  );
};

export default Testimonial;
