import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      try {
        await getAccessTokenSilently();
      } catch (error) {
        console.log("Token validation error:", error);
        navigate("/login");
      }
    };

    if (isAuthenticated) {
      checkToken();
    }
  }, [getAccessTokenSilently, navigate, isAuthenticated]);

  return <>{children}</>;
};

export default ProtectedRoute; 